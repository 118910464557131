import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { useAuthContext } from "context/AuthContext";
import useUtilities from "customHooks/useUtilities";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useCheckoutStore } from "../../CheckoutState";
import LottieUri from "../../components/LottieUri";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
//@ts-ignore
import { getMixpanel } from "config/mixpanel";
import { useReviewModalContext } from "context/ReviewModalContext";
import { useIsomorphicLayoutEffect } from "customHooks/useIsomorphicLayoutEffect";
import { getAnalytics, logEvent } from "firebase/analytics";
import { fbPurchase } from "utils/facebookPixel";
import { sendEvent } from "utils/sendEvent";

const CheckoutEndStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("checkout_end");
};
const CheckoutEndStepBody: React.FC = () => {
  const router = useRouter();
  const { data: utilities } = useUtilities();
  const { donor } = useAuthContext();
  const [setModalVisible, checkoutEnd, checkoutOrder] = useCheckoutStore(
    (state) => [state.setModalVisible, state.checkoutEnd, state.checkoutOrder]
  );

  useEffect(() => {
    const logPurchaseEvent = async () => {
      if (checkoutOrder) {
        const firebaseAnalytics = getAnalytics();

        if (firebaseAnalytics) {
          logEvent(firebaseAnalytics, "purchase", {
            currency: checkoutOrder.currency.code,
            value: checkoutOrder.amount,
            transaction_id: checkoutOrder.reference,
          });
        }

        await sendEvent({
          pixel: "Donate",
        });
        if (checkoutOrder?.subscription_setup_details) {
          if (typeof window !== "undefined") {
            window.fbq("track", "Subscribe", {
              currency: checkoutOrder.currency.code.toUpperCase(),
              value: checkoutOrder.amount,
            });
          }
        }
        const mixpanel = getMixpanel();
        mixpanel.track("Purchase", {
          currency: checkoutOrder.currency.code.toUpperCase(),
          value: checkoutOrder.amount,
          "Checkout Order": checkoutOrder.reference,
          Items: checkoutOrder.items,
        });
      }
    };
    logPurchaseEvent();
  }, []);
  const [sentFBEVENT, setSent] = useState(false);
  useIsomorphicLayoutEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.fbq &&
      checkoutOrder &&
      checkoutOrder?.amount &&
      checkoutOrder?.currency &&
      !sentFBEVENT
    ) {
      setSent(true);
      console.log("SENT FB PURCHASE EVENT");
      fbPurchase(checkoutOrder.amount, checkoutOrder.currency.code);
    }
  }, [checkoutOrder, sentFBEVENT]);
  const reviewModal = useReviewModalContext();
  const { t } = useTranslation();
  const handleGoToPath = async () => {
    setModalVisible(false);
    // if (checkoutEnd.suggest_review || donor?.tester) {
    //    reviewModal.handleShowReviewModal("review");
    // }
    if (checkoutOrder.donation_motivation_submission_suggested) {
      reviewModal.setMotivationId(checkoutOrder.id);
      reviewModal.handleShowReviewModal("motivation");
    } else {
      reviewModal.handleShowReviewModal("app");
    }
  };

  return (
    <ModalBodyElementContainer
      className="d-flex flex-column align-items-center"
      id="donate-checkout-end-step"
    >
      <LottieUri
        {...utilities?.animation_icons.successful_payment}
        style={{ width: "100%", maxWidth: 400 }}
      />
      <div
        style={{
          textAlign: "center",
          fontSize: 20,
          marginBottom: 10,
          fontWeight: "bold",
        }}
      >
        {checkoutEnd?.message?.title}
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
          lineHeight: "30px",
          marginBottom: 10,
          fontWeight: "bold",
        }}
      >
        {checkoutEnd?.message?.body}
      </div>
      {checkoutOrder.payer.type === "guest" ? (
        <MolhamButton
          id="donate-checkout-end-btn"
          endIcon="fe-arrow-right"
          label={t("close")}
          className={`w-100`}
          onClick={() => {
            setModalVisible(false);
          }}
        />
      ) : (
        <MolhamButton
          id="donate-checkout-end-btn"
          endIcon="fe-arrow-right"
          label={checkoutEnd?.call_to_action_button.label}
          className={`w-100`}
          onClick={handleGoToPath}
        />
      )}
    </ModalBodyElementContainer>
  );
};
const CheckoutEndStep = {
  name: "CheckoutEndStep",
  title: CheckoutEndStepTitle,
  body: CheckoutEndStepBody,
  // footer: CheckoutEndStepFooter,
};
export default CheckoutEndStep;
