import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
type TModeContext = {
  switchModeBtn: boolean;
  setSwitchModeBtn: React.Dispatch<React.SetStateAction<boolean>>;
  theme: string;
  setTheme: (theme: string) => void;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  isAuto: string;
  setIsAuto: React.Dispatch<React.SetStateAction<string>>;
};
export const ModeContext = createContext<TModeContext>(null);
const ModeProvider = ({ children }) => {
  const [switchModeBtn, setSwitchModeBtn] = useState<boolean>(false);
  const prferences = {
    themeCookie: Cookies.get("theme") || "light",
    colorCookie: Cookies.get("color") || "green",
    colorResetCookie: Cookies.get("colorReset") || "blue", // New cookie to track reset status
  };
  let col;
  let them;
  let auto;
  if (typeof window !== "undefined") {
    col = localStorage.getItem("color");
    them = localStorage.getItem("theme");
    auto = localStorage.getItem("auto");
  }
  const [theme, setTheme] = useState<string>(them || "light");

  const [color, setColor] = useState<string>(col || "green");
  const [isAuto, setIsAuto] = useState<string>(auto || "true");
  useEffect(() => {
    if (prferences.colorResetCookie !== "green") {
      // Reset color to green and set reset cookie to 1
      setColor("green");
      Cookies.set("color", "green");
      Cookies.set("colorReset", "green");
    }
  }, []);
  if (typeof window !== "undefined") {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        autoChanger(e);
      });
  }
  const autoChanger = (e) => {
    if (e.matches === true && isAuto === "true") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else if (e.matches === false && isAuto === "true") {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };
  useEffect(() => {
    if (prferences.themeCookie === "dark") {
      setSwitchModeBtn(true);
    }

    const prefersColorSchemeDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    autoChanger(prefersColorSchemeDark);
  }, []);

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "theme") {
      setTheme(newValue);
    }
    if (key === "color") {
      setColor(newValue);
    }
  };

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("theme", theme);
    Cookies.set("theme", theme);

    if (theme === "dark") {
      setSwitchModeBtn(true);
    }
    if (theme === "light") {
      setSwitchModeBtn(false);
    }
  }, [theme]);
  useEffect(() => {
    localStorage.setItem("color", color);
    Cookies.set("color", color);
  }, [color]);
  const value: TModeContext = useMemo(
    () => ({
      switchModeBtn,
      setSwitchModeBtn,
      theme,
      setTheme: (theme) => {
        setTheme(theme);
        localStorage.setItem("theme", theme);
        Cookies.set("theme", theme);
        setIsAuto("false");
        localStorage.setItem("auto", "false");
        Cookies.set("auto", "false");
      },
      color,
      setColor,
      isAuto,
      setIsAuto,
    }),
    [switchModeBtn, theme, color, isAuto]
  );
  return <ModeContext.Provider value={value}>{children}</ModeContext.Provider>;
};
export function useModeContext(): TModeContext {
  const modeData = useContext(ModeContext);
  if (modeData == null) {
    throw new Error(
      "ModeContext is not available. Make sure you are rendering the component within the ModeProvider."
    );
  }
  return modeData;
}
export default ModeProvider;
