import SelectCountryModal from "components/Profile/SelectCountryModal";
import useInputValueLangaugeDetector from "customHooks/useInputValueLangaugeDetector";
import useModal from "customHooks/useModal";
import useRTL from "customHooks/useRTL";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import { Controller } from "react-hook-form";
import EyeButton from "./AuthModal/EyeButton";
import CustomPhoneInput from "./CustomPhoneInput";

type Props = {
  control: any;
  name: string;
  label?: string;
  labelSize?: "xs" | "sm" | "md" | "lg" | "xl";
  watch: any;
  defaultValue?: any;
  customOnChange?: any;
  placeholder?: string;
  type?: "text" | "phone" | "country" | "email" | "password" | "ig" | "select";
  autoFocus?: boolean;
  fullWidth?: boolean;
  icon?: string;
  countryProps?: {
    utilities: any;
    locale: string;
    specifiedCountries?: string[];
  };
  selectProps?: {
    options: any[];
  };
  disabled?: boolean;
};

function MolhamInput({
  control,
  name,
  placeholder,
  watch,
  type,
  defaultValue,
  icon,
  countryProps,
  fullWidth,
  selectProps,
  label,
  customOnChange,
  disabled,
  labelSize = "md",
  autoFocus = false,
}: Props) {
  const { t } = useTranslation("common");
  const { dir, onInputFocus, onInputBlur } = useInputValueLangaugeDetector(
    watch(name)
  );
  const isRTL = useRTL();
  const modal = useModal();
  const [showPass, setShowPass] = useState(true);
  const toggleShowPass = () => {
    setShowPass(!showPass);
  };
  const inputContainerClass = `${fullWidth ? "w-100" : ""} d-flex flex-column `;
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { name, onBlur, onChange, ref, value },
        fieldState: { error },
      }) => {
        const props = {
          autoFocus,
          onChange: (e) => {
            if (customOnChange) customOnChange(e);
            onChange(e);
          },
          defaultValue,
          value,
          name,
          ref,
          onFocus: onInputFocus,
          onBlur: (e) => {
            onBlur();
            onInputBlur(e);
          },
          id: name,
          type: type ? type : "text",
          placeholder: placeholder ? placeholder : t(name),
        };
        const LabelComp = () => {
          return (
            <label className={"form-label px-0 fs-" + labelSize} htmlFor={name}>
              {t(label)}
            </label>
          );
        };
        const ErrorComp = () => {
          return error?.message ? (
            <div
              className="invalid-feedback mx-0 mb-3 mt-2"
              style={{ width: "fit-content" }}
            >
              {error?.message}
            </div>
          ) : (
            <div className="mb-3" />
          );
        };
        if (type === "phone") {
          return (
            <div className={inputContainerClass}>
              {label && <LabelComp />}
              <CustomPhoneInput {...props} /> <ErrorComp />
            </div>
          );
        }
        if (type === "password") {
          return (
            <div className={inputContainerClass}>
              {label && <LabelComp />}
              <div className="position-relative">
                <div className="input-group">
                  <span className={`input-group-text`} id="basic-addon1">
                    <i className="ai-key"></i>
                  </span>
                  <input
                    disabled={disabled}
                    {...props}
                    dir={dir}
                    className={`form-control lh17 cursor`}
                    type={!showPass ? "text" : "password"}
                  />
                </div>
                <EyeButton
                  isRTL={isRTL}
                  showPass={showPass}
                  toggleShowPass={toggleShowPass}
                  LR={8}
                  additionalClasses="text-primary"
                />
              </div>
              <ErrorComp />
            </div>
          );
        }
        if (type === "email") {
          return (
            <div className={inputContainerClass}>
              {label && <LabelComp />}
              <div className="input-group">
                <span className={`input-group-text`} id="basic-addon1">
                  <i className="ai-mail"></i>
                </span>
                <input
                  disabled={disabled}
                  {...props}
                  dir={watch("email")?.length > 0 ? "ltr" : dir}
                  className={`form-control lh17 cursor`}
                  type="email"
                  autoComplete="email"
                  style={{
                    direction: dir,
                  }}
                />
              </div>
              <ErrorComp />
            </div>
          );
        }
        if (type === "ig") {
          return (
            <div className={inputContainerClass}>
              {label && <LabelComp />}
              <div className="input-group">
                <span className={`input-group-text`} id="basic-addon1">
                  <i className={icon}></i>
                </span>
                <input
                  disabled={disabled}
                  {...props}
                  dir={dir}
                  className={`form-control lh17 cursor`}
                  type="text"
                />
              </div>
              <ErrorComp />
            </div>
          );
        }
        if (type === "country") {
          const { utilities, locale, specifiedCountries } = countryProps;
          return (
            <div className={inputContainerClass}>
              {label && <LabelComp />}
              <input
                disabled={disabled}
                {...props}
                dir={dir}
                className={`form-control lh17 cursor`}
                onClick={() => {
                  modal.handleShowModal();
                }}
                value={
                  utilities?.countries.find((item) => item?.code === value)
                    ?.name?.[locale]
                }
                //disable writing in the input
                readOnly
              />
              <ErrorComp />
              <SelectCountryModal
                options={
                  specifiedCountries
                    ? utilities?.countries
                        .filter((country) => {
                          console.log(country?.code);

                          return specifiedCountries.includes(country?.code);
                        })
                        .map((item) => {
                          return {
                            label: item?.name?.[locale],
                            value: item?.code,
                          };
                        })
                    : utilities?.countries.map((item) => {
                        return {
                          label: item?.name?.[locale],
                          value: item?.code,
                        };
                      })
                }
                placeHolder={t("search")}
                selectedOption={value}
                onOptionChange={(option) => {
                  modal.handleCloseModal();
                  onChange(option?.value);
                }}
                handleCloseModalOffCanvas={modal.handleCloseModal}
                showModalOffCanvas={modal.showModal}
              />
            </div>
          );
        }
        if (type === "select") {
          return (
            <div className={inputContainerClass}>
              {label && <LabelComp />}
              <select
                disabled={disabled}
                {...props}
                dir={dir}
                className={`form-control lh17 cursor`}
              >
                {selectProps?.options?.map((item) => {
                  return (
                    <option key={item?.value} value={item?.value}>
                      {item?.label}
                    </option>
                  );
                })}
              </select>
              <ErrorComp />
            </div>
          );
        }
        return (
          <div className={inputContainerClass}>
            {label && <LabelComp />}

            <input
              disabled={disabled}
              {...props}
              dir={dir}
              className={`form-control lh17`}
            ></input>
            <ErrorComp />
          </div>
        );
      }}
    />
  );
}

export default MolhamInput;
