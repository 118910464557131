/* eslint-disable react/display-name */
import styled from "@emotion/styled";
import MainAuthModalComponent from "components/ReuseableCompos/AuthModal/MainAuthModalComponent";
import { useAuthContext } from "context/AuthContext";
import { AuthModalContext } from "context/AuthModalContext";
import useMobile from "customHooks/useMobile";
import useRTL from "customHooks/useRTL";
import { useContext, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import BodyLock from "utils/bodyLock";
import CloseBtn from "../../CloseBtn";
import {
  useCheckoutCanGoBack,
  useCheckoutCurrentStep,
  useCheckoutShowHeader,
  useCheckoutStore,
} from "./CheckoutState";
import StripeElementsWrapper from "./components/StripeElementsWrapper";
const DonateModalWrapper = () => {
  const currentStep = useCheckoutCurrentStep();
  const canGoBack = useCheckoutCanGoBack();
  const isRTL = useRTL();
  const { donor } = useAuthContext();

  const isMobile = useMobile("md");
  const { setStep } = useContext(AuthModalContext);

  const {
    modalVisible,
    hideModal,
    goBack,
    stepHistory,
    checkoutOrder,
    forceLogin,
  } = useCheckoutStore((state) => ({
    modalVisible: state.modalVisible,
    hideModal: () => {
      setTimeout(() => setStep(0), 300);
      state.setModalVisible(false);
      currentStep?.name === "ConfirmationStep" &&
        checkoutOrder?.donation_motivation_submission_suggested;
    },
    goBack: state.popStepFromHistory,
    stepHistory: state.stepHistory,
    checkoutOrder: state.checkoutOrder,
    forceLogin: state.forceLogin,
  }));
  const showHeader = useCheckoutShowHeader();
  const ModalTitle = useMemo(
    () => () => {
      const Title = currentStep?.title;
      if (Title) {
        return (
          <span className="modal-title h4">
            <Title />
          </span>
        );
      } else {
        return null;
      }
    },
    [currentStep?.title]
  );
  const ModalBody = useMemo(
    () => () => {
      const Body = currentStep?.body;
      if (Body) {
        return <Body />;
      } else {
        return null;
      }
    },
    [currentStep?.body]
  );
  const confStepHeaderCheck = () => {
    if (forceLogin && !donor) {
      return false;
    } else if (currentStep?.name === "ConfirmationStep") {
      if (!donor && !checkoutOrder?.payer) {
        return false;
      } else {
        return true;
      }
    } else if (checkoutOrder?.shipping_address_required && !donor) {
      return false;
    } else {
      return true;
    }
  };
  console.log(forceLogin, "forcelogins");
  return (
    <StripeElementsWrapper loadStripe={modalVisible}>
      <StyledCheckoutModal
        className="checkout-modal"
        // @ts-ignore
        size={null}
        show={modalVisible}
        fullscreen={"md-down"}
        key={isMobile.toString()}
        scrollable
        backdrop="static"
        onEnter={() => {
          BodyLock.lock();
        }}
        onExit={() => {
          BodyLock.unlock();
        }}
        onEscapeKeyDown={() => {
          if (canGoBack) {
            goBack();
          } else {
            hideModal();
          }
        }}
        centered
      >
        <>
          {confStepHeaderCheck() ? (
            <>
              {showHeader && confStepHeaderCheck() ? (
                canGoBack ? (
                  <Modal.Header>
                    <span>
                      <button className="back" onClick={goBack}>
                        {isRTL ? (
                          <i className="fe-arrow-right"></i>
                        ) : (
                          <i className="fe-arrow-left"></i>
                        )}
                      </button>
                    </span>
                    <span className=" mx-auto mb-0 text-capitalize d-flex align-items-center">
                      <ModalTitle />
                    </span>
                    <CloseBtn closeModal={hideModal} />
                  </Modal.Header>
                ) : (
                  <Modal.Header>
                    <span className="mb-0 text-capitalize d-flex align-items-center">
                      <ModalTitle />
                    </span>
                    <CloseBtn closeModal={hideModal} />
                  </Modal.Header>
                )
              ) : null}
              <Modal.Body
                className="p-0"
                style={{
                  overflowX: "hidden",
                }}
              >
                <ModalBody />
              </Modal.Body>
              <Modal.Footer className="p-0 border-top-0">
                <div id="modal-footer-portal-host" className="w-100" />
              </Modal.Footer>
            </>
          ) : (
            <MainAuthModalComponent
              additionalOnclose={hideModal}
              fromCheckout={!forceLogin}
              checkoutId={checkoutOrder?.id}
              authReq={checkoutOrder?.authentication_required || forceLogin}
            />
          )}
        </>
      </StyledCheckoutModal>
    </StripeElementsWrapper>
  );
};

export default DonateModalWrapper;

export const StyledCheckoutModal = styled(Modal)`
  @media (max-width: 767.98px) {
    .modal-dialog-scrollable {
      max-height: unset;
    }
  }
  .modal-footer > * {
    margin: 0;
  }
`;
